/* Built In Imports */
import { useEffect } from 'react';

/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import { consoleLog } from '@components/Utility/Shared/SharedService';
/* Services */

/**
 * Renders the ProgramFinder component.
 * @returns {ReactElement} Program Finder component.
 */
const ProgramFinder = () => {
  const getScript = url =>
    new Promise((resolve, reject) => {
      // consoleLog('getScript');
      const script = document.createElement('script');
      script.src = url;
      script.async = true;
      script.onerror = reject;
      script.onload = script.onreadystatechange = function () {
        const loadState = this.readyState;
        if (loadState && loadState !== 'loaded' && loadState !== 'complete')
          return;
        script.onload = script.onreadystatechange = null;
        resolve();
      };

      document.head.appendChild(script);
    });

  const loadPrsSchedule = () => {
    // consoleLog("loadPrsSchedule", PrsSchedule);
    PrsSchedule.initFrame({
      divId: 'programFinder',
      title: 'Program/Events Finder',
      minHeight: '320',
      background: 'faf7f0',
      type: 'in-person',
      category: 'any-category',
      center: 'Isha Institute of Inner-sciences',
      excludeCategory: 'ay-processes,vol-oppurtunities',
      minimal: 1,
    });
  };

  useEffect(() => {
    if (typeof PrsSchedule === 'undefined') {
      consoleLog('PrsSchedule === undefined');
      getScript(
        (location.origin == 'https://isha.sadhguru.org'
          ? 'https://online.innerengineering.com/'
          : 'https://staging.innerengineering.com/') +
        'schedule-assets/js/embed.js'
      ).then(loadPrsSchedule);
    } else {
      // consoleLog('loadForm');
      loadPrsSchedule();
    }

    return () => {
      // document.removeChild(script);
    };
  }, []);

  return (
    <Box id="programFinder">
      {/* <Box
        fontFamily="FedraSansStd-A-medium"
        fontSize="30px"
        lineHeight="1.1"
        color="#02026c"
        textAlign="center"
        my={{
          base: '30px',
          md: '50px',
        }}
      >
        <Box as="h2" mb={1}>
          Upcoming Programs
        </Box>
        <Box textAlign="center" mb="1.5rem">
          <LazyLoadImageComponent
            alt=""
            title=""
            src={`${config.imgPath}/profiles/isha/themes/ishatheme/assets/illustration-divider-1.svg`}
          />
        </Box>
      </Box>
      <Box id="programFinder"></Box> */}
    </Box>
  );
};

export default ProgramFinder;

{
  /* <body>
    <div id="programFinder">
        <iframe src="http://localhost:4001/en/search/embed?background=faf7f0&amp;type=in-person&amp;center=Isha%20Institute%20of%20Inner-sciences&amp;category=vol-oppurtunities&amp;invCategory=true&amp;minimal=1" scrolling="no" frameborder="0" height="320px" style="width: 0; min-width: 100% !important; border: none;" title="Program/Events Finder"></iframe>
    </div>
    <script type="text/javascript">
        const getScript = url => new Promise((resolve, reject) => {
        const script = document.createElement('script')
        script.src = url
        script.async = true
    
        script.onerror = reject
    
        script.onload = script.onreadystatechange = function() {
            const loadState = this.readyState
    
            if (loadState && loadState !== 'loaded' && loadState !== 'complete') return
    
            script.onload = script.onreadystatechange = null
    
            resolve()
        }
    
        document.head.appendChild(script)
    })
    
    const loadPrsSchedule = () => {
        PrsSchedule.initFrame(
            {
                divId: 'programFinder',
                title: 'Program/Events Finder',
                minHeight: '320',
                background: 'faf7f0',
                type: 'in-person',
                category: 'vol-oppurtunities',
                center:'Isha Institute of Inner-sciences',
                invCategory: true,
                minimal: 1,
            }
        ); 
    }
    if (typeof PrsSchedule === 'undefined') {
      getScript(( (location.origin == "https://isha.sadhguru.org") ? "https://online.innerengineering.com/":'http://staging.innerengineering.com/') + "schedule-assets/js/embed.js")
            .then(loadPrsSchedule)
    } else {
        loadForm(loadPrsSchedule)
    }
</script>
</body> */
}
